:root {
    --background-color: #F2F9FD;
    --light-blue-color: #5BB9E8;
    --frame: 20px silver;
    --font-basic: 'Calibri', sans-serif;
    --light-orange: #EC7729;
    --light-orange-hover: #B25D25;
    --dark-orange: #FF570A;
}

body {
    height: 100%;
    font-size: 100%;
}

html {
    font-size: 100%;
    height: 100%;
}

* {
    margin: 0px;
    padding: 0px;
}
a {
    text-decoration: none;
    color: black;
}
a:hover {
    font-weight:bold;
    color: currentColor;
}

.button {
    width: 100px;
    height: 40px;
    border-radius: 10px;
    background-color: var(--light-orange);
    color: black;
    font-weight: bold;
    margin: 5px;
    box-shadow: 3px 3px gray;
    font-family: var(--font-basic);
}
.button:hover {
    color: red;
}

.header-small-item {
    font-family: 'Indie Flower', cursive;
    font-size: 30px;
}
.unfold-button {
    width: auto;
    height: 40px;
    text-align: center;
    background-color: var(--background-color);
    border: none;
}
.unfold-button:hover {
    color: red;
}
.dropdown-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background-color);
}
.dropdown-item {
    width: 100%;
    background-color: var(--background-color);
    color: var(--light-orange);
    font-family: 'Indie Flower', cursive;
    font-size: 30px;
    padding-bottom: 4px;
}
.dropdown-item:hover {
    border-bottom: 2px solid black;
    color: black;
}
.arrow-icon {
    position: relative;
    bottom: 3px;
}

@media screen and (min-width: 769px) {

.header-small {
    display: none;
}

.header-section {
    display: flex;
    padding-top: 100px;
    background-color: var(--background-color);
    justify-content: flex-start;

}

.header-container-small {
    display: none;
}

.header-container {
    display: flex;
    padding: 30px 20vw 0px 20vw;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
    position: sticky;
    top: 0px;
    background-color: var(--background-color);
}

.header-item {
    font-family: 'Indie Flower', cursive;
    color: var(--light-orange);
    font-weight: bold;
    padding: 15px;
    font-size: 20px;
    padding-top: 20px;
}

.logo-image {
    max-width: 200px;
}
.header-item:hover {
    color: black;
    text-decoration: underline;
}

.shop-header-container {
    display: none;
}

.search-input {
    position: relative;
    top: 10px;
}

.shop-dropdown-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: var(--background-color);
    font-size: 18px;
    z-index: 7;
}

.shop-dropdown-item {
    padding: 5px;
}
}

@media screen and (max-width: 1010px) and (min-width: 769px) {
    .header-container {
        display: none;
    }

    .header-small {
        display: none;
    }

    .header-container-small {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        background-color: var(--background-color)
    }

    .unfold-button {
        padding-right: 20vw;
        padding-top: 10px;
        font-size: 30px;
    }
}


@media screen and (max-width: 769px) {
    .header-container {
        display: none;
    }
    .header-small {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        background-color: var(--background-color);
    }

    .header-container-small {
        display: none;
    }

    .logo-image {
        max-height: 50px;
    }

    .shop-header-container {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        position: fixed;
        bottom: 0px;
        left: 0px;
        background-color: var(--dark-orange);
        height: 70px;
        width: 100%;
    }

    .shop-header-item {
        font-size: 30px;
    }
    .shop-header-menu {
        background-color: transparent;
        border: none;
        font-size: 30px;
        height: 30px;
    }

    .show-customer-message {
        display: none;
    }

    .categories-list-container {
        display: none;
    }

    .mobile-category-list {
        display: flex;
        flex-direction: column;
        position: fixed;
        bottom: 70px;
        width: 100%;
        font-size: 40px;
        background-color:var(--dark-orange);
    }

    .mobile-category-item {
        padding: 5px;
        font-size: 30px;
        height: 45px;
    }

    .mobile-category-item:hover {
        text-decoration: underline;
    }

    .shop-dropdown-menu {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        background-color: var(--dark-orange);
        position: fixed;
        bottom: 70px;
        right: 0px;
        font-size: 18px;
    }

    .shop-dropdown-item {
        padding: 5px;
        font-size: 30px;
        height: 45px;
    }

    .search-input {
        position: fixed;
        bottom: 80px;
        width: 100%;
    }

    .search-button {
        position: relative;
        right: 10px;
        width: 40px;
    }

}

@media screen and (max-width: 1120px) {

    .logo-image {
        max-height: 50px;
    }
}



.basket-items {
    display: none;
    width: 25px;
    height: 25px;
    position: relative;
    top: -10px;
    left: 30px;
    background-color: white;
    font-size: 20px;
    border: 1px solid red;
    border-radius: 50% 50%;
    text-align: center;

}

.shop-header-profile {
    display: flex;
}

.shop-header-item span {
    font-size: 18px;
    padding-right: 10px;
    padding-top: 6px;
}

.footer-sites-container {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    flex-wrap: wrap;
}
/* @media screen and (max-width: 700px) {
    .footer-sites-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
    }
} */


.footer-site {
    color: var(--dark-orange)
}

.footer-sites-category {
    display: flex;
    flex-direction: column;
    min-width: 170px;
}

.footer-container {
    display: flex;
    justify-content: center;
    font-size: 15px;
    font-family: var(--font-basic);
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 70px;
}

.sign {
    flex: 5;
    text-align: right;
}

.footer-item {
    padding-left: 20px;
}

.price-list-image {
    position: absolute;
    top: 15%;
    width: 80vw;
    height: auto;
    margin-left: 10vw;
}

.price-list-overall {
    min-height: 90vh;
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0px;
}

@media screen and (min-width: 700px) {

.price-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: var(--frame);
    border-style: groove;
    border-radius: 20px;
    box-shadow: 10px 10px darkslategray;
    width: 80%;
    max-width: 1000px;
    height: 100%;
    min-height: 500px;
    background-image: url('../images/spodnie.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
}

@media screen and (max-width:699px) {
    .price-list-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: var(--frame);
        border-style: groove;
        border-radius: 20px;
        box-shadow: 10px 10px darkslategray;
        width: 80%;
        max-width: 1000px;
        height: 100%;
        min-height: 500px;
        background-color: white;
        background-size: cover;
}
}

.price-list-item {
    border: 1px silver;
    background-color: var(--background-color);
    box-shadow: 2px 2px silver;
    border-radius: 5px 5px;
    margin: 50px 0px;
    
}
.price-list-item h5 {
    text-align: center;
    font-family: var(--font-basic);
    font-size: 22px;
    padding: 10px;
}

.price-item {
    font-size: 20px;
    color: black;
    font-family: var(--font-basic);
    padding: 10px;
    margin-left: 30px;
}
@media screen and (min-width: 1270px) {

.price-list-buttons {
    display: flex;
    justify-content: space-between;
}
.price-list-options {
    display: none;
}
}
@media screen and (max-width: 1269px) {
    .price-list-buttons {
        display: none;
    }
    .price-list-options {
        width: 70%;
        border: none;
        text-align: center;
        background-color: var(--light-orange);

    }
    .price-list-options:hover {
        color: black;
    }
    .price-list-dropdown {
        display: flex;
        flex-direction: column;
        width: 70%;
        animation-name: dropdown-list;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
    }
    .price-list-option {
        background-color: var(--light-orange);
        font-family: var(--font-basic);
        border: none;
        border-bottom: 1px solid black;
        height: 25px;
        border-radius: 10px;
    }
    .price-list-option:hover {
        text-decoration: underline;
    }

    @keyframes dropdown-list{
        0%{transform: translateX(0);}
        100%{transform: translateX(40);}
    }
}

.landing-overall {
    display: flex;
    justify-content: center;
}
.landing-container {
    background-color: var(--background-color);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (min-width: 577px) {
    .landing-item {
    border: var(--frame);
    font-family: var(--font-basic);
    border-style: groove;
    border-radius: 20px;
    box-shadow: 10px 10px darkslategray;
    margin: 30px;
    background-color: white;
    display: grid;
    grid-template-columns: 200px auto;
    grid-template-rows: 50px auto;
    max-width: 1000px;
}

.landing-item-header {
    margin: 10px;
}

.landing-item-image {
    width: auto;
    height: 120px;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 3px 3px silver;
}

.landing-item-content {
    font-size: 20px;
    padding: 10px;
}
}

@media screen and (max-width: 576px) {
    .landing-item {
    border: var(--frame);
    font-family: var(--font-basic);
    border-style: groove;
    border-radius: 20px;
    box-shadow: 10px 10px darkslategray;
    margin: 30px;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.landing-item-header {
    padding: 10px;
}

.landing-item-image {
    width: 200px;
    height: auto;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 3px 3px silver;
    align-self: center;
}

.landing-item-content {
    font-size: 20px;
    padding: 10px;
}
}
.contact-overall {
    min-height: 90vh;
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0px;
    font-family: var(--font-basic);
    font-size: 20px;
}

.contact-container {
    display: flex;
    flex-direction: column;
    border: var(--frame);
    border-style: groove;
    border-radius: 20px;
    box-shadow: 10px 10px darkslategray;
    width: 80%;
    max-width: 1000px;
    background-color: white;
}
.contact-item {
    margin-left: 20px;
    margin-top: 10px;
}

.square-icon {
    font-size: 0.5em;
    padding-bottom: 2.5px;
}

.map {
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
}

@media screen and (max-width: 550px) {
    .map {
        width: 80%;
        height: auto;
    }
}


.about-me-overall {
    min-height: 90vh;
    background-color: var(--background-color);
    display: flex;
    justify-content: center;
    padding: 50px 0px;
    font-family: var(--font-basic);
    font-size: 20px;
}

.about-me-container {
    display: flex;
    flex-direction: column;
    border: var(--frame);
    border-style: groove;
    border-radius: 20px;
    box-shadow: 10px 10px darkslategray;
    width: 80%;
    max-width: 1000px;
    background-color: white;
}
.about-me-header {
    padding: 15px;
}

@media screen and (min-width: 576px) {
.about-me-item {
    background-color: white;
    display: grid;
    grid-template-columns: 220px auto;
    grid-template-rows: auto;
    max-width: 1000px;
}
}

@media screen and (max-width: 575px) {
    .about-me-item {
        background-color: white;
        display: flex;
        flex-direction: column;
    }

    .about-me-image {
        align-self:center;
    }
    }

.about-me-image {
    width: 200px;
    height: auto;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 3px 3px silver;
}

.about-me-div {
    padding: 20px;
}

.about-me-paragraph {
    text-align: justify;
    text-indent: 15px;
}

.shop-container {
    min-height: 90vh;
}


.customer-form {
    min-height: 90vh;
    display: flex;
    flex-direction:column;
    width: 85vw !important;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
    font-family: var(--font-basic);
}

.customer-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.customer-form-header {
    font-size: 25px;
    padding: 5px;
}

.customer-form-field {
    padding: 5px;
}

.customer-form-field>input {
    border-radius: 5px 5px;
    padding: 5px;
    box-shadow: 2px 2px gray;
    max-width: 300px;
}

.customer-form-field>label {
    padding-right: 10px;
    font-weight: bold;
}

.categories-list-container {
    font-size: 25px;
    margin: 10px;
    width: 15vw;

}

.categories-list-header {
    padding-bottom: 10px;
    border-bottom: 1px solid gray;
    font-size: 30px;
}

.categories-list-item {
    padding: 5px;
}
.category-selected {
    padding: 5px;
    color: var(--light-orange);
}

.category-selected:hover {
    color: var(--light-orange);

}

.categories-list-item:hover {
    position: relative;
    left: 20px;
}

.shop-content {
    display: flex;
    justify-content: center;
}
@media screen and (min-width: 769px) {

    .shop-filter-container-mobile {
        display: none;
    }
    .shop-filter-container {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        padding: 15px;
        justify-content: space-around;
    }
    .filter-input {
        border-radius: 5px 5px;
        width: 65px;
        height: 30px;
    }
}

@media screen and (max-width: 768px) {
    .shop-filter-container {
        display: none
    }
    .shop-filter-container-mobile {
        display: flex;
        flex-direction: column;
    }
    .filter-mobile-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .filter-mobile-header {
        width: 100%;
        height: 50px;
        background-color: var(--light-orange);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        padding: 5px;
    }

    .filter-form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .filter-mobile-header>* {
        margin: 0px 5px;
    }

    .filter-button {
        background-color: transparent;
        border: none;
    }

    .filter-input {
        border-radius: 5px 5px;
        width: 65px;
        height: 30px;
    }
}


.shop-items {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    justify-content: center;
    width: 85vw
}

.one-card {
    padding: 15px;
}

.image {
    height: 300px;
}

.card {
    height: 400px;
}

.product-card {
    border: 1px solid grey;
    width: 310px;
    height: 450px;
    margin: 15px;
}

.product-button-basket {
    display: flex;
    justify-content: center;
}


@media screen and (max-width: 600px) {

    .shop-items {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        justify-content: center;
        width: 100vw;
    }
    .product-card {
        border: 1px solid grey;
        width: 310px;
        height: 450px;
        margin: 15px;
    }
    .place-image {
        height: 300px;
        width: 300px;
        display: flex;
        justify-content: center;

    }
    }

.product-image {
    height: 100%;
    max-width: 300px;
}

.place-image {
    height: 300px;
    width: 300px;
    display: flex;
    justify-content: center;

}


.product-name {
    font-size: 20px;
    font-weight: bold;
}

.product-price {
    font-size: 18px;
}

.search-button {
    background-color: lightcyan;
    min-width: 20px;
    max-width: 100px;
    border: 1px solid black;
    border-radius: 5px;
    margin-left: 2px;
    position: relative;
}

.search-button i {
    position: relative;
    top: 5px;
}

.product-view-container {
    display: flex;
    flex-direction: column;
    width: 85vw;
    justify-content: flex-end;
}
.product-view-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 50px;
    height: 100%;
}

.product-view-name {
    width: 100%;
    position: relative;
    left: 10vw;
    border-bottom: 1px solid black;
}


.product-view-image {
    width: 500px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



.main-image {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main-image img {

    height: auto;
    width: auto;
    max-width: 300px;
    max-height: 300px;
}

@media screen and (max-width: 600px) {
    .product-view-image {
        width: 350px;
        height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .main-image img {


        height: auto;
        width: auto;
        max-width: 300px;
        max-height: 300px;
    }
}


.shop-preview-images-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.shop-product-image {
    height: 100px;
    width: 100px;
    margin: 10px;
    text-align: center;
    background-color: white
}

.shop-product-image img {
    height: auto;
    width: auto;
    max-width: 90px;
    max-height: 90px;
}
@media screen and (max-width: 600px) {
.shop-product-image {
    height: 60px;
    width: 60px;
    margin: 7px;
    text-align: center;
    background-color: white
}

.shop-product-image img {
    height: auto;
    width: auto;
    max-width: 50px;
    max-height: 50px;
}

}
.arrow {
    background-color: transparent;
    border: none;
}

.big-photo {
    height: auto;
    width: auto;
    max-width: 80vw;
    max-height: 80vh;
}

.product-view-species {
     width: 40%;
     display: flex;
     flex-direction: column;
     position: relative;
     padding: 15px;
     margin-top: 50px;
}
@media screen and (max-width: 769px) {
.product-view-species {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 15px;
    margin-top: 0px;
}
}

.product-view-description {
    border-top: 1px solid gray;
    padding: 15px;
}

.available {
    color: green;
}

.unavailable {
    color: red;
}

.product-view-species h2 {
    padding: 10px;
}

.product-view-species div {
    padding: 10px;
}
.species-price {
    font-size: 25px;
}

.species-shipment {
    font-size: 17px;
}

.species-availability {
    font-size: 18px;
}
.species-how-many {
    font-size: 12px;
    font-weight: bold;
}
.species-amount {
    display: flex;
}

.button-amount {
    height: 40px;
    width: 30px;
    border: none;
}

.input-amount {
    width: 30px;
    height: 40px;
    text-align: center;
}

.plus {
    height: 30px;
    background-color: green;
    border-radius: 50% 50%;
    margin: 5px;
    font-weight: bold;
}

.minus {
    height: 30px;
    background-color: red;
    border-radius: 50% 50%;
    margin: 5px;
    font-weight: bold;
}

.delete-basket {
    border: 2px solid black;
    background-color: red;
    font-weight: bold;
    width: 25px;
    height: 25px;
    border-radius: 50% 50%;

}

.species-basket {
    padding: 10px;
}

.button-basket {
    width: 200px;
    height: 40px;
    background-color: var(--light-orange);
    border-radius: 5px 5px;
    color:lightgray;
    border: none;
    margin-top: 10px;
}

.button-basket-small {
    width: 70px;
    height: 30px;
    background-color: var(--light-orange);
    border-radius: 5px 5px;
    color:lightgray;
    border: none;
    margin: 0px 5px;
}
.button-disabled{
    width: 200px;
    height: 40px;
    background-color:gray;
    border-radius: 5px 5px;
    color:lightgray;
    border: none;
}

.button-basket:hover {
    background-color: var(--light-orange-hover);
}

.query-message {
    width: 100%;
    text-align: center;
    font-size: 18px;
}

.links{
    text-align: left;
}

.basket-content {
    width: 85vw;
    display: flex;
    flex-direction: column;
}

.empty-basket {
    width: 85vw;
}

.basket-header {
    font-size: 20px;
    margin: 15px;
}

.basket-summary {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
}

.summary-content-span {
    font-weight: bold;
    font-size: 1.5rem;
}

.basket-action {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 50px;
}

.under-basket-container {
    display: flex;
    justify-content: space-around;
}

@media screen and (max-width: 400px) {
    .under-basket-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .basket-action {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 50px;
    }
}

.paczkomaty {
    display: flex;
    flex-direction: column;
}

.shop-form {
    width: 30vw!important;
}
.identities-container {
    display: flex;
    justify-content: flex-start;
}

@media screen and (max-width: 769px) {
    .shop-form {
        width: 80vw!important;
    }
    .identities-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.shop-form-container {
    margin: 10px;
    padding: 10px;
    width: 100%;
}

.shop-form-header {
    font-size: 20px;
    padding: 10px;
    margin-bottom: 10px;
}



.empty-error {
    display: none;
    font-size: 12px;
    font-weight: bold;
    color: #ff3333;
}

.summary-identities {
    display: flex;
    justify-content: space-between;
    width: 75vw;
    align-self: center;
}

.summary-identities-child {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 10px;
}

.summary-identities-child div {
    margin-bottom: 5px;
}

@media screen and (max-width:769px) {
    .summary-identities {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 75vw;
        align-self: center;
    }
}

.bold {
    font-weight: bold;
}

.under-basket-container div {
    margin-top: 10px;
}

.question-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 10vh;
    left: 30vw;
    width: 40vw;
    height: 60vh;
    min-height: 500px;
    background-color:white;
    border-radius: 5px;
    box-shadow: 1px 1px gray;
    border: 1px solid gray;
    z-index: 2;
}

.question-form-header {
    font-size: 4vh;
    height: 7vh;
    width: 100%;
    position:absolute;
    top: 0px;
    background-color: var(--light-orange);
    text-align: center;
    padding: 1vh;
}

.question-form-close {
    position: absolute;
    right: 2px;
    background-color: transparent;
    border: none;
    font-size: 2vh;
}

.question-form-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.question-form-email {
    width: 70%;
    height: 25px;
    font-size: 18px;
    margin: 20px;
}

.question-form-message {
    margin: 20px;
    width: 30vw;
    height: 20vh;
    font-size: 18px;
}

.question-response {
    width: 250px;
    height: 150px;
    border: 1px solid green;
    background-color: lightgreen;
    font-size: 17px;
    top: 45vh;
    left: 45vw;
    z-index: 2;
    position: fixed
}



#mask {
    background-color: black;
    opacity: 0.5;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 1;
    position:fixed;
}

.basket-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 20vh;
    left: 30vw;
    width: 40vw;
    height: 40vh;
    background-color:white;
    border-radius: 5px;
    box-shadow: 1px 1px gray;
    border: 1px solid gray;
    z-index: 2;
}

.basket-form-message {
    padding-bottom: 5vh;
}
.basket-form-button-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

@media screen and (max-width: 769px) {
    .question-form-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 5vh;
        left: 5vw;
        width: 90vw;
        height: 90vh;
        background-color:white;
        border-radius: 5px;
        box-shadow: 1px 1px gray;
        border: 1px solid gray;
        z-index: 2;
    }

    .question-form-email {
        width: 100%;
        height: 25px;
        font-size: 18px;
        margin: 20px;
    }

    .question-form-message {
        margin: 20px;
        width: 60vw;
        height: 30vh;
        font-size: 18px;
    }

    .question-response {
        width: 30vw;
        height: 50px;
        border: 1px solid green;
        background-color: lightgreen;
        font-size: 17px;
        top: 20vh;
        left: 35vw;
    text-align: center;
        z-index: 2;
        position: fixed
    }

    .basket-form-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 20vh;
        left: 5vw;
        width: 90vw;
        height: 60vh;
        background-color:white;
        border-radius: 5px;
        box-shadow: 1px 1px gray;
        border: 1px solid gray;
        z-index: 2;
    }

    .basket-form-button-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 100%;
    }
}

.summary-container {
    display: flex;
    padding: 25px;
    flex-direction: column;
    width: 70vw;
    margin-left: 15vw;
}

.summary-header {
    align-self: center;
}

p.summary-message {
    text-indent: 10px;
}

.summary-button-container {
    display: flex;
    justify-content: space-around;
}

.summary-button-container>* {
    padding: 10px;
}

@media screen and (max-width: 550px) {
    .summary-button-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}

.customer-panel-container {
    display: flex;
    flex-wrap: wrap;
    width: 85vw;
    padding-top: 20px;
}

.customer-panel-container h2 {
    width: 100%;
    text-align: center;
}

.customer-panel-form {
    width: 50% !important;
    padding-bottom: 30px;
}

@media screen and (max-width: 660px) {
    .customer-panel-form {
        width: 100% !important;
        padding-bottom: 30px;
    }
}

.customer-orders-container {
    width: 85vw;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
}

.customer-orders-container h2 {
    width: 100%;
    text-align: center;
}

.orders-customer-table {
    width: 75vw;
    align-self: center;
    border: 1px solid gray;
    border-collapse: collapse;
}

.orders-customer-table thead {
    height: 60px;
    font-size: 18px;
    background-color: var(--light-orange);
}

.orders-customer-table th, .orders-customer-table td {
    border: 1px solid gray;
    border-collapse: collapse;
    padding: 10px;
}

.order-details-fold {
    display: none;
}
.order-details-fold td {
    border: none;
}

.order-details-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.order-details-table {
    border-right: 1px solid gray;
    padding: 5px;
}

.order-details-table td {
    padding: 2px;
}

.order-details-products {
    width: 100%;

}

.order-details-products table {
    border-collapse: collapse;
}

.order-details-products td {
    border: 1px solid gray;
}

.order-details-products-price {
    text-align: right;
}

.details-shipment td, .details-value td {
    border: none;
}
.order-comment {
    width: 100%;
}

.fold-details {
    border: 1px solid gray;
    font-size: 18px;
    padding: 3px;
    background-color: var(--light-orange)
}

.fold-details:hover {
    background-color: var(--light-orange-hover)
}

.fold-details:active {
    padding: 1px;
    border: 2px solid gray;
}

.navigation-line {
    display: flex;
    padding-bottom: 8px;
    font-size: 25px;
    height: 40px;
}
.navigation-line > * {
    padding-left: 5px;
}

textarea {
    padding: 10px;
}

.pagination {
    text-align: center;
}

.bigger-photo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top:0vw;
    left: 0vh;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    z-index: 2;
}

.site-container {
    width: 70vw;
    margin-left: 7.5vw;
    margin-right: 7.5vw;
    padding: 25px;
}

.site-container h2 {
    text-align: center;
}

.accepts-container {
    display: flex;
    flex-direction: column;
    max-width: 80vw;
    margin-top: 40px;
}

.accepts-container>* {
    margin-bottom: 10px;
}

.checkbox {
    width: 20px;
    height: 20px
}

.search-error-container {
    display: flex;
    flex-direction: column;
}












